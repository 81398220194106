import React from 'react'
import Breadcrumbs from '../components/Breadcrumbs'
import ImageHeader from '../components/ImageHeader'
import CardGrid from '../components/card-grid'
import {graphql} from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

const PageTemplate = ({data}) => {
    let termUri = data.wpTermNode.uri
    termUri = termUri.slice(0, -1)
    termUri = termUri.slice(0, termUri.lastIndexOf("/"))
    const termType = data.wpTermNode.nodeType
    return (
        <Layout>
            <Seo
                title={`${data.wpResourceType.name} on ${data.wpTermNode.name}`}
                description={`Browse all ${data.wpResourceType.name} on ${data.wpTermNode.name} on Bible to Life.`}
            />
            <ImageHeader title={`${data.wpResourceType.name} on ${data.wpTermNode.name}`}
                         image={data.wp.resourcesGeneralSettings.resourceLibraryHeader.headerImage.sourceUrl}/>
            <Breadcrumbs title={data.wpTermNode.name} isResource={true} termType={termType}
                         termUri={termUri}/>
            <CardGrid items={data.allWpMereResource.edges} termType={termType}/>
        </Layout>
    )
}

export default PageTemplate

export const query = graphql`
    query($id: Int!, $type: String!) {
        wpResourceType(slug: {eq: $type}) {
            name
        }
        wpTermNode(databaseId: {eq: $id}) {
            name
            description
            nodeType
            uri
        }
        wp {
            resourcesGeneralSettings {
                resourceLibraryHeader {
                    headerImage {
                        sourceUrl
                    }
                }
            }
        }
        allWpMereResource(
            filter: {topics: {nodes: {elemMatch: {databaseId: {eq: $id}}}}, resourceTypes: {nodes: {elemMatch: {slug: {eq: $type}}}}}
        ) {
            edges {
                node {
                    id
                    title
                    slug
                    resourceTypes {
                        nodes {
                            name
                            slug
                        }
                    }
                    scriptures {
                        nodes {
                            name
                            slug
                        }
                    }
                    featuredImage {
                        node {
                            mediaDetails {
                                sizes {
                                    name
                                    sourceUrl
                                }
                            }
                        }
                    }
                    multiauthor {
                        nodes {
                            slug
                            name
                        }
                    }
                }
            }
        }
    }
`